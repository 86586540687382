import { default as a882vfbIkNbMeta } from "/app/pages/-/a.vue?macro=true";
import { default as contactsHSHl3sOssUMeta } from "/app/pages/contacts.vue?macro=true";
import { default as _91uuid_93q7EuKWsIKUMeta } from "/app/pages/editorial/[uuid].vue?macro=true";
import { default as indexn0yioGxUVpMeta } from "/app/pages/editorial/index.vue?macro=true";
import { default as featuringkyoB0DggkuMeta } from "/app/pages/featuring.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexmjydbmT8M8Meta } from "/app/pages/services/index.vue?macro=true";
import { default as _91fid_93PQRgufk1S9Meta } from "/app/pages/services/schedule/[fid].vue?macro=true";
export default [
  {
    name: a882vfbIkNbMeta?.name ?? "--a",
    path: a882vfbIkNbMeta?.path ?? "/-/a",
    meta: a882vfbIkNbMeta || {},
    alias: a882vfbIkNbMeta?.alias || [],
    redirect: a882vfbIkNbMeta?.redirect || undefined,
    component: () => import("/app/pages/-/a.vue").then(m => m.default || m)
  },
  {
    name: contactsHSHl3sOssUMeta?.name ?? "contacts",
    path: contactsHSHl3sOssUMeta?.path ?? "/contacts",
    meta: contactsHSHl3sOssUMeta || {},
    alias: contactsHSHl3sOssUMeta?.alias || [],
    redirect: contactsHSHl3sOssUMeta?.redirect || undefined,
    component: () => import("/app/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93q7EuKWsIKUMeta?.name ?? "editorial-uuid",
    path: _91uuid_93q7EuKWsIKUMeta?.path ?? "/editorial/:uuid()",
    meta: _91uuid_93q7EuKWsIKUMeta || {},
    alias: _91uuid_93q7EuKWsIKUMeta?.alias || [],
    redirect: _91uuid_93q7EuKWsIKUMeta?.redirect || undefined,
    component: () => import("/app/pages/editorial/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexn0yioGxUVpMeta?.name ?? "editorial",
    path: indexn0yioGxUVpMeta?.path ?? "/editorial",
    meta: indexn0yioGxUVpMeta || {},
    alias: indexn0yioGxUVpMeta?.alias || [],
    redirect: indexn0yioGxUVpMeta?.redirect || undefined,
    component: () => import("/app/pages/editorial/index.vue").then(m => m.default || m)
  },
  {
    name: featuringkyoB0DggkuMeta?.name ?? "featuring",
    path: featuringkyoB0DggkuMeta?.path ?? "/featuring",
    meta: featuringkyoB0DggkuMeta || {},
    alias: featuringkyoB0DggkuMeta?.alias || [],
    redirect: featuringkyoB0DggkuMeta?.redirect || undefined,
    component: () => import("/app/pages/featuring.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexmjydbmT8M8Meta?.name ?? "services",
    path: indexmjydbmT8M8Meta?.path ?? "/services",
    meta: indexmjydbmT8M8Meta || {},
    alias: indexmjydbmT8M8Meta?.alias || [],
    redirect: indexmjydbmT8M8Meta?.redirect || undefined,
    component: () => import("/app/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: _91fid_93PQRgufk1S9Meta?.name ?? "services-schedule-fid",
    path: _91fid_93PQRgufk1S9Meta?.path ?? "/services/schedule/:fid()",
    meta: _91fid_93PQRgufk1S9Meta || {},
    alias: _91fid_93PQRgufk1S9Meta?.alias || [],
    redirect: _91fid_93PQRgufk1S9Meta?.redirect || undefined,
    component: () => import("/app/pages/services/schedule/[fid].vue").then(m => m.default || m)
  }
]